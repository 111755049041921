import React from "react"
import Helmet from "react-helmet"
export default function Layout({ children }) {
  return (
    <>
      <Helmet title="Ordinacija opće / obiteljske medicine Smiljevac"></Helmet>
      {children}
    </>
  )
}
