import css from "@emotion/css"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import Helmet from "react-helmet"
import EU from "../components/eu"
import Galerija from "../components/galerija"
import Godisnji1 from "../components/godisnji-1"
import Whatsapp from "../components/whatsapp"
import Obavijest from "../components/Obavijest-1"

export default function OrdinacijaSilvija() {
  const slike = useStaticQuery(graphql`
    {
      hero: file(name: { regex: "/silvijaiivana/" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      sestra: file(name: { regex: "/sestra-marijana/" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
            presentationHeight
          }
        }
      }
      tim: file(name: { regex: "/tim-1/" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      martina: file(name: { regex: "/Martina/" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)
  return (
    <>
      <Helmet title="Ordinacija opće / obiteljske medicine Smiljevac 1"></Helmet>
      <div className="py-2 text-center bg-gray-100">
        <div className="container">
          <Link to="/" className="text-gray-900">
            {" "}
            <h1 className="text-lg font-semibold sm:text-2xl ">
              Ordinacija opće/obiteljske medicine Smiljevac 1
            </h1>
          </Link>
        </div>
      </div>
      <Obavijest></Obavijest>
      <div className="bg-blue-900">
        <div className="container">
          <div className="flex flex-col flex-wrap items-center justify-center py-12 md:flex-row md:py-20">
            <div className="w-full mb-12 text-center md:w-1/2 md:mb-0 md:text-left">
              <h2 className="text-4xl font-semibold text-white">
                Martina Atelj,
              </h2>
              <p className="mb-4 text-2xl text-blue-100">dr. med. </p>
              <h2 className="text-4xl font-semibold text-white">
                {" "}
                Marijana Šindija,
              </h2>
              <p className="text-2xl text-blue-100">med. sestra</p>
            </div>
            <div className="w-full md:w-1/2">
              <Image
                style={{
                  maxWidth: slike.tim.sharp.fluid.presentationWidth,
                  margin: "0 auto",
                }}
                fluid={slike.tim.sharp.fluid}
              ></Image>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="relative bg-blue-100">
        <div className="absolute top-0 left-0 hidden w-full h-full md:w-1/2 md:block">
          <iframe
            title="gmap1"
            className="w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2864.6692027800045!2d15.249274115744353!3d44.11081723073607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4761fa8fab2f61d3%3A0xf23d150fd4abbe60!2sUl.+Antuna+Barca+5a%2C+23000%2C+Zadar!5e0!3m2!1shr!2shr!4v1534142640150"
          ></iframe>
        </div>
        <div className="container">
          <div className="flex flex-col flex-wrap items-center justify-center md:flex-row">
            <div className="w-full md:w-1/2">
              <iframe
                title="gmap2"
                className="block h-56 -mx-4 md:hidden"
                css={css`
                  width: calc(100% + 2rem);
                `}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2864.6692027800045!2d15.249274115744353!3d44.11081723073607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4761fa8fab2f61d3%3A0xf23d150fd4abbe60!2sUl.+Antuna+Barca+5a%2C+23000%2C+Zadar!5e0!3m2!1shr!2shr!4v1534142640150"
              ></iframe>
            </div>
            <div className="w-full px-6 py-8 md:w-1/2 md:px-12 md:py-20">
              <h3>
                <b>
                  <i>Gdje smo?</i>
                </b>
              </h3>
              <h4>Antuna Barca 5A, 23 000 ZADAR </h4>
              <h3>
                <b>
                  <i>Kontakt:</i>
                </b>
              </h3>
              <p>
                <b> Tel. </b> + 385 23 / 311 269
                <br />
                <b>Mob.</b> + 385 99 616 3266
                <br />
                <b>E-mail:</b>{" "}
                <a href="mailto:smiljevac1@ordinacija-smiljevac.com">
                  smiljevac1@ordinacija-smiljevac.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="container py-12 text-center"
          css={css`
            max-width: 960px;
          `}
        >
          <h3>
            <b>
              <i>Radno vrijeme:</i>
            </b>
          </h3>
          <p>
            <b>
              <u>Parni datumi popodne</u> od 13:00 do 20:30 sati,{" "}
              <u>neparni ujutro</u> od 07:00 do 14:30 sati.
            </b>
          </p>
          <p>
            U slučaju popodnevne smjene pacijenti se primaju od 13:30 sati.{" "}
          </p>
          <p>Pauza od 10:30 do 11:00 sati, odnosno od 16:30 do 17:00 sati.</p>
          <p>Zadnji sat radnog vremena namijenjen je kućnim posjetama.</p>
          <h3>
            {" "}
            <b>
              <i>Telefonske konzultacije doktora</i>
            </b>{" "}
          </h3>
          <p>
            Telefonske konzultacije doktora se mogu dobiti u jutarnjoj smjeni od
            12:30 do 13:30 sati odnosno u popodnevnoj smjeni od 18:30 do 19:30
            sati na telefonski broj <a href="tel:+38523311266">023 / 311 266</a>{" "}
            .
          </p>
          <h3>
            {" "}
            <b>
              <i>Naručivanje</i>
            </b>{" "}
          </h3>
          <p>
            Naručivanje na pregled, narudžba kronične terapije i uputnica za
            planirane specijalističke preglede/dijagnostičke pretrage… vrši se
            pozivom na broj telefona{" "}
            <b>
              <a href="tel:+38523311269">023/311-269</a>
            </b>
            , pozivom ili slanjem sms-a na broj mobitela{" "}
            <b>
              <a href="tel:+385996163266">099/6163 266</a>
            </b>{" "}
            ili putem elektroničke pošte:{" "}
            <a href="mailto:smiljevac1@ordinacija-smiljevac.com">
              smiljevac1@ordinacija-smiljevac.com
            </a>
            <b>
              {" "}
              u jutarnjoj smjeni od 07:30 do 10:30 sati odnosno u popodnevnoj
              smjeni od 13:30 do 16:30 sati
            </b>
          </p>
          <p>
            Možete nas kontaktirati i na{" "}
            <a href="https://wa.me/+385996163266">Whatsapp</a> na broj mobitela
            099 6163 266
          </p>
          <p>
            <Whatsapp></Whatsapp>
          </p>
          <p>Molimo pacijente da se naručuju na pregled. </p>
          <p>
            <b>
              Naručeni pacijenti, trudnice, osobe s invaliditetom i hitni
              slučajevi imaju prednost.{" "}
            </b>
          </p>
          <h3>
            {" "}
            <b>
              <i>Vađenje krvi</i>
            </b>{" "}
          </h3>
          <p>
            Uzorci za primarnu laboratorijsku dijagnostiku se uzimaju u
            ordinaciji <b>ponedjeljkom ili četvrtkom</b> (ovisno da li se taj
            tjedan radi ujutro ili popodne) od 07:00 do 08:00 sati
            <br />
            <a href="http://www.bolnica-zadar.hr/files/kako_laboratorij.pdf">
              Kako se pripremiti za laboratorijske pretrage???
            </a>
          </p>
        </div>
      </div>
      <Galerija ambulanta="1"></Galerija>
      <EU></EU>
      <div className="py-6 text-center bg-gray-200">
        <Link
          className="px-4 py-2 text-sm font-semibold tracking-wide text-white uppercase bg-blue-600"
          to="/cjenik/"
        >
          Cjenik
        </Link>
      </div>
      <footer className="bg-blue-900">
        <div className="container py-2 text-center text-white">
          Ordinacija opće / obiteljske medicine Smiljevac ©{" "}
          {new Date().getFullYear()}
        </div>
      </footer>
    </>
  )
}
